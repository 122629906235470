/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function () {
        if (!Modernizr.objectfit) {
          objectFitImages('img.object-fit');
        }
        $footerHeader = $('#footer-sitemap');
        $footerSitemapTabs = $footerHeader.find('.footer-sitemap--tab');
        $footerSites = $('#footer-sitemap-sites');

        $footerSitemapTabs.each(function () {
          $(this).click(function (e) {
            $this = $(this);
            if (!$this.hasClass('active')) {
              e.preventDefault();
              $footerHeader.find('.footer-sitemap--header').addClass('active');
              $footerSitemapTabs.removeClass('active');
              $this.addClass('active');
              var blogID = $this.data('blogid');
              $footerSites.find('.footer-sitemap--site').removeClass('active');
              $footerNav = $footerSites.find("[data-blogid='" + blogID + "']");
              $footerNav.addClass('active');
            }
          });
        });

        $nav = $('#nav');
        $btnMenuPrimary = $('#btn-menu-primary');
        $btnMenuPrimary.click(function (e) {
          e.preventDefault();
          $(this).toggleClass('menu-button__close');
          $nav.toggleClass('nav__active');
        });

        $conso = $('.consomation');
        if ($conso.length > 0) {
          $conso.find('.consomation--row').click(function (e) {
            e.preventDefault();
            $(this).toggleClass('active');
          });
        }

        $('.menu-dropdown--wrapper.menu-item-has-children').find('> a').click(function (e) {
          $this = $(this);
          $parent = $this.parents('.menu-dropdown--wrapper');
          $dropDown = $parent.find('.menu-dropdown');
          $root = $this.parents('.menu-dropdown--root');
          if ($dropDown.length >= 1) {
            e.preventDefault();
            $root.find('.menu-dropdown--wrapper').not($parent).removeClass('active');
            $parent.toggleClass('active');
          }
        });

        $('.menu-pannel--item.menu-item-has-children').find('> a').click(function (e) {
          e.preventDefault();
          $this = $(this);
          $parent = $this.parent();
          toggle = $parent.hasClass('active');
          $('.menu-pannel--item.menu-item-has-children').removeClass('active');
          if (!toggle) {
            $parent.addClass('active');
          }
        });

        $('.group-selector--current').click(function (e) {
          e.preventDefault();
          $this = $(this);
          $parent = $this.parents('.group-selector');
          $parent.toggleClass('active');
        });

        var tempScroll = 0;

        var actionScroll = function () {
          scrollTop = $(this).scrollTop();
          scrollBottom = scrollTop + $(this).height();
          if (scrollTop != tempScroll) {
            if (scrollTop > 120) {
              $('body').addClass('scrolled');
            } else {
              $('body').removeClass('scrolled');
            }

            if (scrollTop < tempScroll) {
              $nav.addClass('nav__fixed');
            } else {
              $nav.removeClass('nav__fixed');
            }
            tempScroll = scrollTop;
          }
        };

        if ($('.ssk').length) {
          SocialShareKit.init();
        }

        if ($('.tabulation').length) {
          $('.tabulation').each(function () {
            $tabulation = $(this);
            $htab = $tabulation.find('.js-htab');

            $htab.click(function (e) {
              e.preventDefault();
              $this = $(this);
              $ptab = $this.parents('.tabulation');
              $htab = $ptab.find('.js-htab');
              $btabs = $ptab.find('.js-btabs');
              $btab = $btabs.find('.js-btab');

              $htab.removeClass('active');
              $btab.removeClass('active');

              $this.addClass('active');
              $btabs.find('[data-tab=' + $this.data('tab') + ']').addClass('active');
            });
          });
        }


        $(window).scroll(actionScroll);

        var options = {
          cssClass: {
            container: 'menu-drilldown--container',
            root: 'menu-drilldown--root',
            sub: 'menu-drilldown--sub',
            back: 'menu-drilldown--back'
          }
        };

        $('.menu-drilldown').drilldown(options);

        if ($('.chart--js').length) {
          Chart.plugins.unregister(ChartDataLabels);

          $('.chart--js').each(function () {
            $this = $(this);

            var chart = new Chart($this, {
              type: 'pie',
              data: {
                labels: $this.data('label'),
                datasets: [{
                  data: $this.data('chiffre'),
                  backgroundColor: $this.data('color')
                }]
              },
              options: {
                legend: {
                  display: false
                },
                tooltips: {
                  callbacks: {
                    label: function (tooltipItem, data) {
                      var idx = tooltipItem['index'];
                      var caption = data.labels[idx];
                      var captionValue = data['datasets'][0]['data'][idx];
                      return caption + ':' + captionValue + '%';
                    }
                  }
                }
              }
            })
          });
        }

        $('.archive-refs__input').each(function () {
          $(this).change(function () {
            $('#archive-refs__form').submit();
          });
        });

        $('.chart-bar--js').each(function () {
          $this = $(this);

          chiffres = $this.data('chiffre');
          labels = $this.data('label');
          colors = $this.data('color');

          var datas = chiffres.map(function (value, index) {
            data = [];
            data.push(value);
            return {
              label: labels[index],
              data: data,
              order: 1,
              backgroundColor: colors[index]
            };
          });

          var chartBar = new Chart($this, {
            plugins: [ChartDataLabels],
            type: 'bar',
            skipNull : true,
            data: {
              datasets: datas
            },
            options: {
              plugins: {
                datalabels: {
                  color: '#ffffff',
                  //display: 'none',
                  //display: none,
                  //formatter: function (value, context) {
                    //return Math.round(value) + '%\n';
                  //},
                  /*font: {
                    weight: 'normal',
                    size: 26,
                    lineHeight: 'normal',
                  }*/
                }
              },
              legend: {
                display: false
              },
              scales: {
                y: {
                  beginAtZero: true
                },
                x: {
                  beginAtZero: true
                },
                xAxes: [{
                  stacked: true
                }],
                yAxes: [{
                  stacked: true
                }]
              }
            }
          })
        });

        $('a.js-popup-iframe').each(function () {
          $this = $(this);
          var href = $this.data("src");

          href: href,
            $this.colorbox({
              href: href,
              'iframe': true,
              innerWidth: '80%',
              innerHeight: '70%',
            });
        });
        $('a.js-popup').each(function () {
          $this = $(this);
          var content_type = $this.data('content-type');
          $this.colorbox({
            'iframe': (content_type == 'iframe') ? true : false,
            innerWidth: '80%',
            innerHeight: '70%',
          });
        });

        $popup = $('.popup')
        if ($popup.length) {
          $popup.each(function (e) {
            $this = $(this);
            $this.find('.js-popup-close').click({
              $parent: $this
            }, function (e) {
              e.preventDefault();
              e.data.$parent.remove();
            });
          });
        }

        new WOW({
          offset: 150,
        }).init();

        $('select:not(.search-form--espace):not(.no-js)').selectize({

        });

        $('#header-search-btn').click(function (e) {
          $('#header-search').toggleClass('active');
        });

        $('.search-form').each(function () {
          $this = $(this);
          $this.find('.search-form--espace').change(function () {
            var action = $(this).val();
            $this.attr("action", action);
          });
        });

        $('.faq-subcat--item').each(function () {
          $faqSubcatItem = $(this);
          $faqSubcatItem.find('.faq-subcat--btn').click({
            faqSubcatItem: $faqSubcatItem
          }, function (e) {
            e.preventDefault();
            $parentFaqSubcatItem = e.data.faqSubcatItem
            $parentFaqSubcatItem.toggleClass('active');
          });
        });

        $('.faq-faq--item').each(function () {
          $faqFaqItem = $(this);
          $faqFaqItem.find('.faq-faq--btn').click({
            faqFaqItem: $faqFaqItem
          }, function (e) {
            e.preventDefault();
            $parentFaqFaqItem = e.data.faqFaqItem
            $parentFaqFaqItem.toggleClass('active');
          });
        });

        if ($faq = $('#faq')) {
          if (hash = window.location.hash) {
            $faqItem = $(hash);
            $faqItem.addClass('active');
            $faqItem.parents('.faq-subcat--item').addClass('active');
          }
        }

        if ($breve = $('.js-breve')) {
          $breve.find('.js-breve-close').click(function (e) {
            e.preventDefault();
            $breve.remove();
          });
        }

        if ($helpButton = $('.form--help-button')) {
          $helpButton.click(function (e) {
            e.preventDefault();
            $(this).parents('.form--annotate').find('.form--help-box').toggleClass('active');
          });
        }

        $('.slider-mod-bandeau-picto-txt-slider').slick({
          autoplay: false,
          autoplaySpeed: 4000,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: true,
          adaptiveHeight: true,
          nextArrow: '<span class="slider-mod--arrow slider-mod--arrow__next"><i class="icons8-long-arrow-right"></i></span>',
          prevArrow: '<span class="slider-mod--arrow slider-mod--arrow__prev"><i class="icons8-long-arrow-left"></i></span>',
        });

        $('#main-slider-picture').slick({
          autoplay: true,
          autoplaySpeed: 4000,
          slidesToShow: 1,
          slidesToScroll: 1,
          fade: true,
          arrows: false,
          infinite: false,
          asNavFor: '#main-slider-content'
        });

        $slideContent = $('#main-slider-content');
        $slideContent.on('init', function (event, slick, direction) {
          $(this).show();
        });
        $slideContent.slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          asNavFor: '#main-slider-picture',
          fade: true,
          dots: true,
          arrows: true,
          adaptiveHeight: true,
          infinite: false,
          nextArrow: '<span class="slider--arrow slider--arrow__next"><i class="icons8-long-arrow-right"></i></span>',
          prevArrow: '<span class="slider--arrow slider--arrow__prev"><i class="icons8-long-arrow-left"></i></span>',
          focusOnSelect: true
        });

        function isIE(userAgent) {
          userAgent = userAgent || navigator.userAgent;
          return userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1 || userAgent.indexOf("Edge/") > -1;
        }

        $('.svg-container').each(function () {
          svgHeight = $(this).find('svg').height();
          $(this).height(svgHeight);
        });

        if (isIE() == false) {
          var svgToZoom = null;
          var svgInitialHeight = null;
          $('.svg-zoomer').click(function () {
            parent = $(this).closest('.svg-holder'); // Container dor the targeted svg
            svgContainer = parent.find('.svg-container');

            // SVG need a fixed height to avoid resizing by the library
            svgInitialHeight = parent.height();
            parent.addClass('zoomed-svg');

            svg = parent.find('svg'); // The targeted SVG
            svg.addClass('svg-to-zoom-pan'); // Add a class to target the SVG with the svgPanZoom method, because this method doesn't accept a DOM Object as argument (only string)

            svgContainer.css('height', '100%');
            svg.css('height', '100%');

            svgToZoom = svgPanZoom('.svg-to-zoom-pan', {
              minZoom: 1,
            });
          })

          // Trigger ZoomIn method
          $('.svg-zoom-in').click(function () {
            parent = $(this).closest('.svg-holder'); // Container dor the targeted svg
            svg = parent.find('svg'); // The targeted SVG

            if (svg.hasClass('svg-to-zoom-pan')) {
              svgToZoom.zoomIn();
            }
          });

          // Trigger ZoomOut method
          $('.svg-zoom-out').click(function () {
            parent = $(this).closest('.svg-holder'); // Container dor the targeted svg
            svg = parent.find('svg'); // The targeted SVG

            if (svg.hasClass('svg-to-zoom-pan')) {
              svgToZoom.zoomOut();
            }
          });

          $('.svg-dezoomer').click(function () {
            parent = $(this).closest('.svg-holder'); // Container dor the targeted svg
            svg = parent.find('svg'); // The targeted SVG

            // Only fire methods if the svg is zoomable / pannable, to avoid errors in the console
            if (svg.hasClass('svg-to-zoom-pan')) {
              svg.height(svgInitialHeight);
              svgContainer.height(svgInitialHeight);
              parent.removeClass('zoomed-svg');

              svgToZoom.resize();
              svgToZoom.fit();
              svgToZoom.center();

              svgToZoom.destroy();
              delete svgToZoom;

              $(svg).removeClass('svg-to-zoom-pan');
            }
          });
        } else if (isIE() == true) {
          $('.svg-zoom-dezoom').each(function () {
            $(this).hide();
          })
        }



        $svg_ids = [];
        $('.popup-svg').each(function () {
          $curr_id = $(this).attr('id').split('-').pop();
          $svg_ids.push($curr_id);
        })

        $.each($svg_ids, function (index, current) {
          $('#' + current).addClass('cursorPointer');

          $('#' + current).click(function () {
            $('.popup-svg').each(function () {
              $(this).removeClass('show');
            })

            $('#popup-' + current).addClass('show');
            $('.popup-svg--overlay').addClass('show');
          })
        })

        function closeSvgPopup() {
          $('.popup-svg.show').each(function () {
            $(this).removeClass('show');
          });
          $('.popup-svg--overlay').removeClass('show');
        }

        $('.popup-svg--close').click(closeSvgPopup);
        $('.popup-svg--overlay').click(closeSvgPopup);

        $('.filleul--wrapper').each(function () {
          $this = $(this);
          if (!$this.is(':last-child')) {
            var $btn = $('<button class="button">Ajouter un Filleul</button>');
            $this.append($btn);
            $btn.click(function (e) {
              e.preventDefault();
              $(this).parents('.filleul--wrapper').next().addClass('active');
              $(this).remove();
            });
          }
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
        if ($('select.gform_autocomplete ')) {
          $('select.gform_autocomplete ').on('select2:select', function (e) {
            var $select = $(this);
            $.ajax({
              url: '/check/communes-secteur',
              type: 'POST',
              data: {
                city: $select.select2('val')
              },
              dataType: 'json',
              success: function (data) {
                var $nextElement = $select.parent().parent().next();
                var $nextElementInput = $nextElement.find('input');
                $nextElementInput.val(data.secteur).change();
              }
            });
          });
        }

        // Code for try zip/select method (without autocomplete plugin)
        var emptyTrueSelect = function (select) {
          $(select).selectize()[0].selectize.destroy();
          var oldOptions = select.getElementsByTagName('option');
          var length = oldOptions.length;
          for (var x = 0; x < length; x++) {
            oldOptions[0].parentNode.removeChild(oldOptions[0]);
          }
        }
        var addDataToTrueSelect = function (select, data) {
          for (var x = 0; x < data.length; x++) {
            var option = document.createElement('option');
            option.setAttribute('value', data[x]);
            option.textContent = data[x];
            select.appendChild(option);
          }
          $(select).selectize();
        }

        if ($('.dynamic-zip')) {
          $('.dynamic-zip').each(function () {
            var $zipInput = $(this).find('input');
            $zipInput.change(function () {
              if ($zipInput.val().length === 5) {
                var zipValue = $zipInput.val();
                $.ajax({
                  url: '/get/commune-via-zip',
                  type: 'POST',
                  data: {
                    zip: zipValue
                  },
                  dataType: 'json',
                  success: function (data) {
                    $selectsContainer = $zipInput.parent().parent().next();
                    trueSelect = $selectsContainer.find('select')[0];
                    falseSelect = $selectsContainer.find('.gfield_select.selectize-control')[0];
                    emptyTrueSelect(trueSelect);
                    addDataToTrueSelect(trueSelect, data);
                  }
                });
              }
            });
            $zipInput.prop('maxlength', '5');
          });
        }
      }
    },
    // Home page
    'home': {
      init: function () {

      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function () {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

// form offres d'emploi animation on click and autofiled field with readonly
if (document.querySelector('.jobFormButton')){

  var wpcf7Elm = document.querySelector( '.wpcf7' );

  document.addEventListener( 'wpcf7mailsent', function(e) {
    // console.log(e);
    var oef_url = window.location.href + '?ofm=ok';
    window.location.replace(oef_url);
  }, false );

}

document.addEventListener("DOMContentLoaded", function(){
  // console.log('loaded');
  if (document.querySelector('.jobFormButton')){

    var wpcf7Elm = document.querySelector( '.wpcf7' );

    document.addEventListener( 'wpcf7mailsent', function(e) {
      var oef_url = window.location.href + '?ofm=ok';
      window.location.replace(oef_url);
    }, false );

    document.addEventListener( 'wpcf7mailsent', function() {
      document.querySelector('.offre--form').classList.toggle('open');
    }, false );

    document.querySelector('.jobFormButton').addEventListener('click',function(){
      document.querySelector('.offre--form').classList.toggle('open');
      var job_name = document.querySelector('.offre--entitled').textContent.trim();
      job_service = document.querySelector('.offre--service h2').textContent.trim();
      job_entity = document.querySelector('.offre--service h3').textContent.trim(),
      job_date = document.querySelector('.section.offresdemploi').getAttribute('date');
      job_forclusion = document.querySelector('.section.offresdemploi').getAttribute('forclusion');
      document.querySelector('.name_job_js input').value=job_name;
      document.querySelector('.service_job_js input').value=job_service;
      document.querySelector('.entity_job_js input').value=job_entity;
      document.querySelector('.date_job_js input').value=job_date;
      document.querySelector('.forclusion_job_js input').value=job_forclusion;
    });

    wpcf7Elm.querySelector('.form--group--input--offre input').addEventListener('click', function () {
        wpcf7Elm.querySelector('.form--group--input--offre input').style.visibility = "hidden";
    })
  }
});
